.add-relationship-modal-body {
  min-height: 500px;
}

.add-relationship-search-results-name-column {
  width: 100%;
}

.add-relationship-search-results-checkbox-column {
  padding: 0;
  text-align: end;

  .add-relationship-search-results-already-added {
    font-size: .8em;
    color: #4e555b;
    font-style: italic;
  }
}

.add-relationship-selected-item {
  display: flex;
  justify-content: space-between;
  padding: .5rem;
  align-items: flex-start;
}

.add-relationship-selected-items-header {
  display: flex;
  justify-content: space-between;

  button {
    margin-right: .5em;
  }
}

.add-relationship-selected-item-list {

  .add-relationship-selected-item:nth-child(odd) {
    background-color: #ededed;
  }

  .add-relationship-selected-item:nth-child(even) {
    background-color: #fff;
    border-top: solid 1px #ccc;
  }
}