.inventory-relationship-item {
  list-style-type: none;
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: .75em;
}

.inventory-relationship-list {
  max-width: 400px;
  ul {
    padding-left: 1em;
  }
}