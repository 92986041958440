.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #8C8C8C;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

.dropzone:hover {
    border-color: #bdbdbd;
    color: #545454;
}

.item-attachments-row {
    margin-bottom: 1rem;
}

.add-item-relationship-heading-with-count-label {
    display: inline-block;
    width: 80px;
    font-weight: 600;
}

.add-item-relationship-heading-with-count {
    margin-right: .5em;
}

.item-details-section-break.row {
    margin-top: .75em;
    padding-top: .75em;
    border-top: solid 1px #ddd;
}

.item-details-section-break-sm.row {
    @media only screen and (max-width: 768px) {
        margin-top: .75em;
        padding-top: 1em;
        border-top: solid 1px #ddd;
    }
}

.item-relationship-display {
    max-width: 400px;
}